
import storeService from "@/services/store.service"
import { productToNumberOfJobs } from "@/helpers/mapping"
import { formatPriceWithoutDecimals } from "@/helpers/formatting"
import { getEinBlickProduct, getSizeOfProduct } from "@/helpers/einBlick"

import { mdiChevronRight } from "@mdi/js"

export default {
    name: "EinBlickModalContentSize",
    async fetch() {
        const products = await storeService.getProducts()
        if (!products) {
            throw new Error("No products found")
        }
        const initialProduct = getEinBlickProduct(
            this.$auth.user.business.subscriptions
        )
        const initialProductSlug = initialProduct?.slug ?? "basic"
        const initialProductPrice = initialProduct?.price ?? 0

        let offeredProducts = []
        if (initialProductSlug.includes("basic"))
            offeredProducts = products.filter((product) =>
                product.slug.includes("basic")
            )
        else if (initialProductSlug.includes("einblick-pro"))
            offeredProducts = products.filter((product) =>
                product.slug.includes("einblick-pro")
            )
        else if (initialProductSlug.includes("einblick"))
            offeredProducts = products.filter(
                (product) =>
                    product.slug.includes("einblick") &&
                    !product.slug.includes("einblick-pro")
            )
        this.currentProducts = offeredProducts.filter(
            (product) => product.price > initialProductPrice
        )
    },
    emits: ["product-click"],
    data: () => ({
        currentProducts: [],
    }),
    computed: {
        offeredProductObjects() {
            return (
                this.currentProducts?.map((product) => {
                    const size = getSizeOfProduct(product)
                    return {
                        id: product.id,
                        size: size,
                        price: formatPriceWithoutDecimals(
                            product.price / 12,
                            true
                        ),
                        details: `<strong>${productToNumberOfJobs(
                            product.slug
                        )} Berufe</strong>`,
                    }
                }) ?? []
            )
        },
    },
    methods: {
        getProduct(id) {
            return this.currentProducts.find((product) => product.id === id)
        },
    },
    icons: {
        mdiChevronRight,
    },
}
